body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.background-wrap {
  overflow: hidden;
  position: relative;
}
.background-image {
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.background-content {
  position: relative;
}

li {
  width: 10em;
  margin: auto;
}

.firearm-list-item {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-icon,
.unlock-icon,
.lock-icon,
.download-icon {
  width: 1em;
  height: 1em;
}

.clock-face {
  width: 1.25em;
  height: 1.25em;
}

@media only screen and (min-width: 400px) {
  .single-shot-page,
  .single-firearm,
  .firearms-list {
    max-width: 90vw;
    margin: auto;
  }
}

@media only screen and (min-width: 600px) {
  .single-shot-page,
  .single-firearm,
  .firearms-list {
    max-width: 80vw;
    margin: auto;
  }
}

@media only screen and (min-width: 850px) {
  .single-shot-page,
  .single-firearm,
  .firearms-list {
    max-width: 70vw;
    margin: auto;
  }
}
